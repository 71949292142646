import React from "react";
import "./index.css";
import RandomSentence from "./RandomSentences";
import Header from "./Header";
import MainContent from "./MainContent";

const App = () => {
  return (
    <div className="page-container">
      <Header />
      <MainContent />
      <RandomSentence />
    </div>
  );
};

export default App;
