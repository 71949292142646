import React from "react";

const MainContent = () => {
  return (
    <div className="centered-text">
      <h2 className="subheader">Nazywam się Piotr Pasławski i jestem programistą.<br />Za niedługo na tej stronie będą naprawdę fajne treści 🤓</h2>
    </div>
  );
};

export default MainContent;
