import React, { useState, useEffect } from "react";

const sentences = [
    "Normalizacja kontaktów międzyludzkich...",
    "Aktywacja generatora chaosu...",
    "Tworzenie sieci połączeń...",
    "Regulacja napięcia emocjonalnego...",
    "Kalibracja macierzy osobowości...",
    "Kaskadowanie szczytów węzłowych...",
    "Równoważenie współczynników zadomowienia...",
    "Przetwarzanie drabiny kariery...",
    "Mapowanie atrybutów wpływów...",
    "Przypisywanie propagatorów mimicznych...",
    "Dołączanie algorytmów rozszerzenia...",
];

const getRandomSentence = () => {
  const randomIndex = Math.floor(Math.random() * sentences.length);
  return sentences[randomIndex];
};

const RandomSentence = () => {
  const [sentence, setSentence] = useState(getRandomSentence());

  useEffect(() => {
    const interval = setInterval(() => {
      setSentence(getRandomSentence());
    }, 2500); // 10000 milisekund = 10 sekund

    return () => clearInterval(interval);
  }, [sentence]);

  return (
    <div className="centered-text">
      <p style={{ textAlign: "center" }}>{sentence}</p>
    </div>
  );
};

export default RandomSentence;
