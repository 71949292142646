import React, { useState, useEffect } from "react";
import "./index.css";

const Header = () => {
  const [header, setHeader] = useState("");
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [isPipe, setIsPipe] = useState(true);
  const headerText = "Sześć! Witam Cię w tym nowym pięknym dniu 👋";

  useEffect(() => {
    let currentCharacter = 0;
    const totalCharacters = headerText.length;

    const interval = setInterval(() => {
      if (currentCharacter <= totalCharacters) {
        setHeader(headerText.substring(0, currentCharacter) + "|");
        currentCharacter++;
      } else {
        clearInterval(interval);
        setAnimationCompleted(true);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (animationCompleted) {
      const blinkInterval = setInterval(() => {
        setIsPipe((prevIsPipe) => !prevIsPipe);
      }, 500);

      return () => clearInterval(blinkInterval);
    }
  }, [animationCompleted]);

  useEffect(() => {
    if (animationCompleted && isPipe) {
      setHeader(headerText);
    }
  }, [animationCompleted, isPipe]);

  return (
    <h1 className="header-container">
      {header}
    </h1>
  );
};

export default Header;
